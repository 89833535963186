module.exports = {
  name: 'sportcitypremium',
  imagePath: '/static/images/sportcity-premium',
  logo: {
    mobile: '/static/images/sportcity-premium/logos/logo.svg',
    desktop: '/static/images/sportcity-premium/logos/logo-full.svg',
    desktopCheckout: '/static/images/sportcity-premium/logos/logo-light.svg',
  },

  social: {
    facebook: 'SportCityPremiumNL',
    instagram: 'SportCityPremium',
  },
  app: {
    appStore: 'https://apps.apple.com/nl/app/sportcity-premium/id614562456',
    appStoreId: '614562456',
    playStore:
      'https://play.google.com/store/apps/details?id=nl.sportcity-premium.android&hl=nl',
  },
  oldSiteUrl: {
    home: 'https://www.sportcity.nl/',
    memberships: 'https://www.sportcity.nl/abonnementen',
  },
  clubs: {
    url: 'https://www.sportcity.nl/clubs',
    slug: 'clubs',
  },
  checkout: {
    hasAddons: false,
    contractPeriod: {
      showPriceDifference: true,
    },
    usesPerfectGym: true,
    onlyShowClub: 28,
    showMembershipPrice: false,
    showFitForFriends: false,
    showFourWeeksDiscount: false,
    showFourWeeksPromo: false,
    logoLink: {
      href: '/',
      linkAs: '/',
    },
    path: '/funnel',
  },
  // @todo: this is a temporary fix, should be removed once these pages are configurable in Contentful
  payment: {
    hideBlocks: true,
  },
  copyright: 'Sportcity Cornelis Schuyt',
  domain: 'https://www.sportcitycornelisschuyt.nl',
  hostname: 'www.sportcitycornelisschuyt.nl',
  login: true,
  loginUrl: 'https://sportcity.perfectgym.com/ClientPortal2/#/Login',
  seo: {
    blockSearchEngines: false,
    googleSiteVerification: '3BgCpK9gRes3ltoA90yLitz1k-J0wHRtQ-5sm6I9DvQ',
    metaTitleSuffix: '| SportCity Cornelis Schuyt',
  },
  brand: 'Sportcity Cornelis Schuyt',
};
