'use client';
import { useRouter } from 'next/navigation';
import { useIntl } from 'src/domains/i18n';
import styles from './index.module.scss';
import { useEffect } from 'react';
import { pushGA4CustomErrorEvent } from 'src/features/shared/utils/ga4/push-ga4-event';
import { GenericError } from 'src/features/error-page/components/generic-error';

export default function Error500Page() {
  const { formatMessage } = useIntl();
  const router = useRouter();

  useEffect(() => {
    pushGA4CustomErrorEvent({
      error_category: 'system error',
      error_type: '500',
      error_message: formatMessage('error-500-page.title'),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericError
      title={formatMessage('error-500-page.title')}
      description={formatMessage('error-500-page.description')}
      buttonLabel={formatMessage('error-500-page.to-home-label')}
      onClick={() => router.push('/')}
      className={styles.container}
    />
  );
}
