const sportcity = require('../config/themes/sportcity')
const sportcityPremium = require('../config/themes/sportcity-premium')

const themeMap = {
  sportcity,
  sportcitypremium: sportcityPremium
}

const getTheme = function (theme) {
  return themeMap[theme]
}

module.exports = getTheme
