export enum ButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Inverted = 'Inverted',
  Club = 'Club',
  Link = 'Link',
  Ghost = 'Ghost',
  SecondaryInverted = 'SecondaryInverted',
  PrimaryInverted = 'PrimaryInverted',
}
