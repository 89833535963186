import classNames from 'classnames';
import styles from './Loading.module.scss';
import { ComponentProps } from 'react';

export interface ILoadingProps extends ComponentProps<'svg'> {
  isSecondary?: boolean;
}

export const Loading = ({
  isSecondary = false,
  className,
  ...props
}: ILoadingProps) => (
  <svg
    width="44"
    height="32"
    viewBox="0 0 44 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(
      styles.loader,
      { [styles.secondary]: isSecondary },
      className,
    )}
    aria-live="polite"
    role="status"
    {...props}
  >
    <rect
      y="8"
      width="12"
      height="16"
      rx="4"
      className={classNames(styles.item, styles.first)}
    />
    <rect
      x="16"
      y="8"
      width="12"
      height="16"
      rx="4"
      className={classNames(styles.item, styles.second)}
    />
    <rect
      x="32"
      y="8"
      width="12"
      height="16"
      rx="4"
      className={classNames(styles.item, styles.third)}
    />
  </svg>
);
