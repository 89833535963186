export const GENERIC_SECTION_TYPES = {
  GROUP_LESSONS: 'Group Lessons',
  SPECIALISTS: 'Specialist Section',
};

export const SECTIONS_BG_COLORS = {
  softPeach: 'Soft-Peach',
  softSand: 'Soft-Sand',
  softYellow: 'Soft-Yellow',
};

export type SectionBackgroundColor = 'Soft-Peach' | 'Soft-Sand' | 'Soft-Yellow';

export enum ButtonDisplayAs {
  PrimaryButton = 'Primary button (yellow)',
  SecondaryButton = 'Secondary button (black)',
  TertiaryButton = 'Tertiary button (ghost)',
  Link = 'Link',
}
