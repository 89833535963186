export const CHECKOUT_URL = 'checkout';
export const CHECKOUT_TYPE_NAME = 'Funnel';

export const FUNNEL_STEP_SLUGS = {
  welkom: 'welkom',
  selectTheClub: 'kies-je-club',
  selectAClub: 'selecteer-een-club',
  selectMembership: 'kies-je-lidmaatschap',
  personalInfo: 'persoonlijke-info',
  summary: 'overzicht',
  addOns: 'kies-je-extras',
};

export const DEFAULT_SLUGS = {
  clubsMap: '/clubs',
  home: '/',
};

export const CONTENTFUL_TAGS = {
  seo: 'seo',
};
